<script context="module">
    export const routeName = '/';
</script>

<script>
    import { onMount } from 'svelte'
    import Phalcon from '../../modules/phalcon'
    import Datepicker from 'UI/Datepicker.html'
    import Backdrop from 'UI/Backdrop.html'
    import { successMsg, warningMsg, errorMsg } from 'UI/Messages.html'
    import AuthService from 'global/services/AuthService.js'

    let session = null
    let totals = {}
    let mounted = false
    let usersMap = {}
    let loading = false
    let date = moment().format('YYYY-MM-DD')

    AuthService.session.subscribe({
        next: v => session = v
    })

    const fetchCouriers = async () => {
        const answer = await Phalcon.api.users.all({ groups_id: 3 })

        if(answer.status == 200) {
            if (answer.payload.items) {
                answer.payload.items.forEach(u => usersMap[u.id] = u)
            }
        } else {
            errorMsg(answer.message)
        }
    }

    const fetchTotals = async () => {
        loading = true 

        const answer = await Phalcon.api.delivery.services.totals({
            date_start: date,
            date_end: date
        })

        if(answer.status == 200) {
            totals = answer.payload.totals
        } else {
            errorMsg(answer.message)
        }

        loading = false
    }

    const dateOnChange = () => {
        if (mounted) fetchTotals()
    }

    onMount(async () => {
        await fetchCouriers()
        await fetchTotals()
        mounted = true
    })

    $: date, dateOnChange()
</script>

{#if session}
    <div class="uk-card uk-card-default uk-card-body">
        <h3 class="uk-margin-remove">Приветствуем Вас, {session.last_name} {session.first_name}</h3>
        <p>На главной странице Вы сможете увидеть краткую информацию за выбранный день</p>
        <div class="uk-flex uk-flex-middle">
            <div class="uk-margin-small-right uk-position-relative" style="width:250px">
                {#if loading}
                    <Backdrop>
                        <div uk-spinner></div>
                    </Backdrop>
                {/if}
                <Datepicker bind:value={date} />
            </div>
            {#if loading}
                <a class="uk-icon-button uk-spinner">
                    <div uk-spinner></div>
                </a>
            {:else}
                <a class="uk-icon-button" uk-icon="refresh" on:click={fetchTotals}></a>
            {/if}
        </div>
    </div>

    {#if totals}
        <div class="uk-card uk-card-default uk-card-body uk-margin-top uk-margin-medium-bottom">
            {#if loading}
                <div uk-spinner></div>
            {:else}
                {#if totals.statuses}
                    <h4 class="uk-margin-remove">Заказов в работе: {totals.delivery_services_quantity}</h4>
                    <div class="uk-child-width-1-4 uk-margin-small-top" uk-grid>
                        {#each totals.statuses as item}
                            {#if item.quantity}
                                <div class="uk-flex uk-flex-center uk-flex-middle">
                                    <div class="uk-text-center status-item uk-padding-small uk-width">
                                        <span class="uk-text-meta">{item.status_name}</span><br>
                                        <b style="color:{item.status_color}">{item.quantity}</b>
                                    </div>
                                </div>
                            {/if}
                        {/each}
                    </div>

                    <h4 class="uk-margin-remove-bottom uk-margin-top">Задействовано курьеров: {Object.keys(totals.delivery_users).length}</h4>
                    <div class="uk-child-width-1-4 uk-margin-small-top" uk-grid>
                        {#each Object.keys(totals.delivery_users) as deliveryUserId}
                            {#if usersMap[deliveryUserId]}
                                <div class="uk-flex uk-flex-center uk-flex-middle">
                                    <div class="status-item uk-padding-small uk-width">
                                        <span class="uk-text-meta">{usersMap[deliveryUserId].last_name} {usersMap[deliveryUserId].first_name}</span><br>
                                        {#each Object.values(totals.delivery_users[deliveryUserId]) as statusItem}
                                            <b class="uk-margin-small-right" style="color:{statusItem.status_color}; cursor: pointer;" uk-tooltip={statusItem.status_name}>{statusItem.quantity}</b>
                                        {/each}
                                    </div>
                                </div>
                            {/if}
                        {/each}
                    </div>
                {/if}
            {/if}
        </div>
    {/if}
{/if}

<style>
    .status-item {
        border: 1px solid #dfe1e5;
        border-radius: 3px;
    }
</style>