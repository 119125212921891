<script context="module">
    import { writable, get } from 'svelte/store'

    export let googleMap
    let bounds
    let currentMarkerClusters = {}

    function _generateIcon(fillColor, innerFillColor = 'white') {
        let icon = {};

        icon.url = `data:image/svg+xml,%3Csvg width='29' height='29' viewBox='0 0 29 29' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M14.3694 0C8.57818 0 3.8667 4.71148 3.8667 10.5026C3.8667 17.6897 13.2656 28.2406 13.6658 28.6863C14.0416 29.1049 14.6979 29.1042 15.073 28.6863C15.4732 28.2406 24.8721 17.6897 24.8721 10.5026C24.872 4.71148 20.1606 0 14.3694 0ZM14.3694 15.7868C11.4557 15.7868 9.08528 13.4164 9.08528 10.5026C9.08528 7.58894 11.4558 5.21853 14.3694 5.21853C17.2831 5.21853 19.6535 7.58899 19.6535 10.5027C19.6535 13.4164 17.2831 15.7868 14.3694 15.7868Z' fill='${fillColor}'/%3E%3Ccircle cx='14.5' cy='10.6331' r='8.7' fill='${innerFillColor}'/%3E%3C/svg%3E`;


        return icon;
    }

    function _generateUserIcon(fillColor, status = "FREE") {
        let icon = {}

        icon.url = `data:image/svg+xml,<svg width="12" height="29" viewBox="0 0 144 352" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M112 40C112 62.0914 94.0914 80 72 80C49.9086 80 32 62.0914 32 40C32 17.9086 49.9086 0 72 0C94.0914 0 112 17.9086 112 40Z" fill="${fillColor}"/><path d="M72 80H39.328C28.8976 80 18.8943 84.1435 11.5189 91.5189C4.14348 98.8943 0 108.898 0 119.328V208C0 212.243 1.68571 216.313 4.68629 219.314C7.68688 222.314 11.7565 224 16 224H32L40 352H104L112 224H128C132.243 224 136.313 222.314 139.314 219.314C142.314 216.313 144 212.243 144 208V119.328C144 108.898 139.857 98.8943 132.481 91.5189C125.106 84.1435 115.102 80 104.672 80H72Z" fill="${fillColor}"/></svg>`

        if (status == 'BUSY') {
            icon.url = `data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="29"       height="29" viewBox="0 0 48 48"><path d="M0 0h48v48h-48z" fill="${fillColor}"/><path d="M28 7.6c1.98 0 3.6-1.61 3.6-3.6s-1.62-3.6-3.6-3.6c-1.99 0-3.6 1.61-3.6 3.6s1.61 3.6 3.6 3.6zm.24 12.4h9.76v-3.6h-7.25l-4-6.66c-.59-1-1.68-1.66-2.92-1.66-.34 0-.67.05-.98.14l-10.85 3.38v10.4h3.6v-7.33l4.21-1.31-7.81 30.64h3.6l5.74-16.22 4.66 6.22v10h3.6v-12.81l-4.98-9.08 1.47-5.74 2.15 3.63z" fill="${fillColor}"/></svg>`;
        }

        return icon;
    }

    export const getMap = () => googleMap
    export const markers = writable({})
    export const polylines = writable([])

    export const createPolylineByServices = (startService, endService) => {
        return new google.maps.Polyline({
            id: startService.id + "-" + endService.id,
            path: [{
                lat: parseFloat(startService.lat),
                lng: parseFloat(startService.lng)
            }, {
                lat: parseFloat(endService.lat),
                lng: parseFloat(endService.lng)
            }],
            strokeColor: 'red',
            strokeOpacity: 0.8,
            strokeWeight: 2,
        });
    }

    export const createMarkerByDeliveryService = (service, onClick = null) => {
        let markerId = 'delivery_service_' + service.id;
        let innerColor = 'white';

        if (!service.delivery_user_id) {
            innerColor = 'rgb(255, 220, 220)';
        } else if (service.status_id == 11) {
            innerColor = 'rgb(202, 255, 202)';
        } else {
            innerColor = 'rgb(248, 248, 144)';
        }

        if (currentMarkerClusters['delivery_services']) {
            for (let i = 0; i < currentMarkerClusters['delivery_services'].length; i++) {
                let m = currentMarkerClusters['delivery_services'][i];
                if (m.id == markerId) {
                    m.setPosition({ 
                        lat: parseFloat(service.lat), 
                        lng: parseFloat(service.lng) 
                    });
                    m.setIcon(service.pickups ? _generateIcon('darkorange', innerColor) : _generateIcon('darkgreen', innerColor));
                    return m;
                }
            }
        }

        let marker = new google.maps.Marker({
            icon: service.pickups ? _generateIcon('darkorange', innerColor) : _generateIcon('darkgreen', innerColor),
            position: { lat: parseFloat(service.lat), lng: parseFloat(service.lng) },
            title: service.address,
            id: markerId,
            title: `№${service.number} ${service.address}`
        });

        if (onClick) {
            marker.removeListener('click');
            marker.addListener("click", onClick);
        }

        return marker;
    }

    export const createMarkerByUserLocation = user => {
        let markerId = 'users_location_' + user.id;
        let color = 'grey'

        if(user.status == 'FREE'){
            color = 'green'
        }

        if(user.status == 'WAITING'){
            color = 'orange'
        }

        if(user.status == 'BUSY'){
            color = 'red'
        }

        if (currentMarkerClusters['users_location']) {
            for (let i = 0; i < currentMarkerClusters['users_location'].length; i++) {
                let m = currentMarkerClusters['users_location'][i];
                if (m.id == markerId) {
                    m.setPosition({ 
                        lat: parseFloat(user.location.lat), 
                        lng: parseFloat(user.location.lng) 
                    });
                    m.setIcon(_generateUserIcon(color));
                    return m;
                }
            }
        }

        return new google.maps.Marker({
            position: { lat: parseFloat(user.location.lat), lng: parseFloat(user.location.lng) },
            title: user.last_name,
            id: markerId,
            title: `${user.last_name} ${user.first_name}`,
            icon: _generateUserIcon(color),
        })
    }

    export const panTo = ({ lat, lng }) => {
        if (googleMap) googleMap.panTo(new google.maps.LatLng(lat, lng))
    }

    export const fitBounds = () => {
        if (googleMap) {
            let bounds = new google.maps.LatLngBounds();
            let clusters = get(markers)

            for(let key in clusters){
                clusters[key].map(m => bounds.extend(m.position));
            }
            
            googleMap.fitBounds(bounds)
        }
    }
</script>

<script>
    import { onMount, onDestroy, setContext } from "svelte";

    import { contextKey } from './context.js'

    setContext(contextKey, {
        getGoogleMap: () => googleMap,
    })

    export let center = { lat: 55.752107, lng: 37.622345 }
    export let zoom = 10
    export let maxZoom = 18
    export let disableDefaultUI = true
    export let polygons = []

    let currentPolylines = []
    let currentPolygons = []
    let mapWrapper

    const updateMarkersOnMap = markersClusters => {
        if(googleMap && markersClusters){
            for(let key in markersClusters){
                if (!currentMarkerClusters[key]) currentMarkerClusters[key] = [];
                if (!markersClusters[key]) markersClusters[key] = [];

                let currentMarkerIds = currentMarkerClusters[key].map(m => m.id);
                let newMarkerIds = markersClusters[key].map(m => m.id);

                currentMarkerClusters[key].forEach(m => {
                    if (!newMarkerIds.includes(m.id)) m.setMap(null);
                });

                currentMarkerClusters[key] = markersClusters[key];

                currentMarkerClusters[key].forEach(m => {
                    if (!currentMarkerIds.includes(m.id)) m.setMap(googleMap);
                });
            }
        }
    }

    const updatePolylinesOnMap = polylinesArr => {
        if(googleMap && polylinesArr){
            currentPolylines.map(p => p.setMap(null))
            currentPolylines = $polylines
            currentPolylines.map(p => p.setMap(googleMap))
        }
    }

    const updatePolygonsOnMap = polygonsArr => {
        if(googleMap && polygonsArr){
            currentPolygons.map(p => p.setMap(null))
            currentPolygons = polygonsArr
            currentPolygons.map(p => p.setMap(googleMap))
        }
    }

    let markersUnsubscribe = markers.subscribe(clusters => updateMarkersOnMap(clusters))
    let polylinesUnsubscribe = polylines.subscribe(p => updatePolylinesOnMap(p))

    onMount(() => {
        googleMap = new google.maps.Map(mapWrapper, {
            center: center,
            zoom: 10,
            maxZoom: 18,
            disableDefaultUI: true
        });

        bounds = new google.maps.LatLngBounds();
    });

    onDestroy(() => {
        googleMap = null
        markersUnsubscribe()
        polylinesUnsubscribe()
        currentMarkerClusters = {}
    });

    $: center, panTo(center)
    $: polygons, updatePolygonsOnMap(polygons)
</script>

<div class="map" bind:this={mapWrapper}></div>

{#if googleMap}
    <slot></slot>
{/if}

<style>
    .map {
        height: 100%;
        width: 100%;
    }
</style>