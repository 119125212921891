<script>
    import { onMount, onDestroy } from 'svelte';

    import ConfirmEmailForm from 'components/auth/AuthConfirmEmailForm.html';
    
    onMount(() => {
        document.querySelector('meta[name="viewport"]').setAttribute('content', 'width=device-width, initial-scale=1');
    })

    onDestroy(() => {
        document.querySelector('meta[name="viewport"]').setAttribute('content', '');
    })
</script>

<div class="wrapper uk-position-fixed uk-position-top-left uk-background-cover uk-flex uk-flex-center uk-flex-middle">
    <div class="uk-width-large uk-height-1-1 uk-flex uk-flex-center uk-flex-middle uk-background-default uk-position-top-right">
        <div class="uk-text-center">
            <img src="/img/logo_expand.svg" width="125" />
            <div class="uk-margin-medium-top">
                <ConfirmEmailForm />
            </div>
        </div>
    </div>
</div>

<style>
    .wrapper {
        width: 100vw;
        height: 100vh;
        background-image: url("/img/auth_bg.jpg");
        z-index: 999;
    }
</style>