<script>
  import { createEventDispatcher } from 'svelte';

  import Phalcon from "modules/phalcon.js"
  import Router from "modules/router.js"

  import Modal from 'UI/Modal.html';
  import Input from 'UI/Input.html';
  import InputNumber from 'UI/InputNumber.html';
  import Button from 'UI/ButtonOld.html';
  import WindowButton from 'UI/ButtonWindowClose.html';
  import Slider from "UI/Slider.html";
  import FilesCollection from "UI/FilesCollection.html";
  import ModalPrint from "UI/PrintModal.html";

  export let legal;
  export let modal;
  export let data = modal.props || {};
  export let columns = modal.columns;
  export let printElem;

  const dispatch = createEventDispatcher();
  
  let alpha = {};
  let size = 0;
  let imagesData;
  let urls = [];

  if (modal.props && modal.props.images) {
    modal.props.images.map(image => {
      urls = [...urls, '/files/image/'+image.number];
    })
  }

  function markField(event, marker) {
    data[marker] = event.detail;
  };

  function sendData () {

    if (modal.props && modal.props.name){
      Phalcon.request('products', 'update', 'POST', {
        products: {...data},
        ids: modal.props.id}).then( answer => {
        if (answer.status === 200) {
          UIkit.notification({
            message: `Данные товара '${modal.props.name}' изменены`,
            status: 'success',
            pos: 'bottom-right',
            timeout: 5000
          });
          dispatch('close');
          dispatch('refresh');
        } else {
          throw new Error(`${answer.status}`)
        }
      })
        .catch( err => {
          UIkit.notification({
            message: {err},
            status: 'warning',
            pos: 'bottom-right',
            timeout: 5000
          });
        })
    } else {
      UIkit.notification({
        message: 'Заполните обязательное поле Наименование!',
        status: 'warning',
        pos: 'bottom-right',
        timeout: 5000
      });
    }
  }

  const print = () => {
    const myPrint = window.open('','myPrint','');
    myPrint.document.write(printElem.innerHTML);
    myPrint.print();
    myPrint.close();
  }

  const close = () => dispatch("close");

  $: if (data){
    size = (data.width * data.height * data.depth) / 1000000;
  }

  function goToTariff () {
    Router.goTo('tariff');
    close();
  }
</script>

<style>
  .wrapper{
    font-size: 14px;
  }
  .circle-image {
    width: 100px;
    height: 100px;
    background-size: cover;
    background-position: center;
  }
  .text__params{
    font-size: 12px;
  }

  .margin-xsmall{
    margin-right: 3px;
    margin-left: 3px;
    font-size: 16px;
  }

  .margin-xsmall-right {
    margin-right: 3px;
    font-size: 16px;
  }

  .margin-xsmall-left {
    margin-left: 3px;
    font-size: 14px;
    line-height: 1.8;
  }
</style>

<ModalPrint {columns} data={modal.props} bind:elem="{printElem}" />

<div class="wrapper">
  <Modal {...modal} modalType="products" on:setData="{(event) => modal.props = Object.assign(modal.props, event.detail)}" on:close on:ready>
    <div class="uk-flex uk-flex-between" slot="header">
      <div>Редактирование товара</div>
      <WindowButton type="plus" on:click={close}></WindowButton>
    </div>

    <div slot="body" class="uk-flex uk-padding-remove">
      <div class="uk-width-2-5">
<!--        <FreeBlock type="{'pay'}" on:click="{goToTariff}"/>-->
        <Slider {urls} bind:formData={imagesData} />
        <FilesCollection />
      </div>
      <div class="uk-width-3-5">
        <div class="uk-flex uk-flex-between">
          <div>Артикул</div>
          <div class="uk-width-2-3">
            <Input name="Артикул" type="text"  on:input="{ (event) => markField(event, 'article')}" value={data.article} />
          </div>
        </div>

        <div class="uk-flex uk-flex-between">
          <div>Наименование<span class="required">*</span></div>
          <div class="uk-width-2-3">
            <Input name="Наименование"  type="text"  on:input="{ (event) => markField(event, 'name')}" value={data.name} />
          </div>
        </div>

        <div class="uk-flex uk-flex-between">
          <div>Цена продажи</div>
          <div class="uk-width-2-3">
            <Input name="Цена продажи"  type="text"  on:input="{ (event) => markField(event, 'price')}" value={data.price} />
          </div>
        </div>

        <div class="uk-flex uk-flex-between">
          <div>Цена закупки</div>
          <div class="uk-width-2-3">
            <Input name="Цена закупки"  type="text"  on:input="{ (event) => markField(event, 'selling_price')}" value={data.selling_price} />
          </div>
        </div>

        <div class="uk-flex uk-flex-between">
          <div>Вес в кг.</div>
          <div class="uk-width-2-3">
            <InputNumber bind:value={data.weight} />
          </div>
        </div>

        <div class="uk-flex uk-flex-between">
          <div>Габариты в cм.</div>
          <div class="uk-flex uk-flex-middle uk-width-2-3 text__params">
          <div class="uk-text margin-xsmall-right">Ш</div>
          <div><InputNumber bind:value={data.width} /></div>
          <div class="uk-flex"><span class="uk-text-meta margin-xsmall-left">x</span> <span class="uk-text margin-xsmall">Г</span></div>
          <div><InputNumber bind:value={data.depth} /></div>
          <div class="uk-flex"><span class="uk-text-meta margin-xsmall-left">x</span> <span class="uk-text margin-xsmall">В</span></div>
          <div><InputNumber bind:value={data.height} /></div>
          </div>
        </div>

          <div>
            <div class="uk-flex uk-flex-right uk-margin-auto-left uk-width-2-3">
              <div>Объем: {size} м3</div>
            </div>
          </div>

        <!-- <div class="uk-flex uk-flex-between">
          <div class="uk-text-small">Прикрепить контрагента</div>
          <div>
            <Legals type="text" placeholder="Прикрепить юр. лицо" bind:data="{legal}" bind:text="{data.legal}"/>
            <Legals type="text" placeholder="Прикрепить юр. лицо" bind:data="{alpha.point}" bind:text="{alpha.legal}"/>
          </div>
        </div> -->

      </div>
    </div>


    <div slot="footer" class="uk-flex uk-flex-between uk-flex-right">
      <a on:click="{print}" uk-icon="icon: print"></a>
      <div class="uk-flex">
        <Button name="Сохранить" on:click={ sendData } status="success"/>
        <Button name="Отменить" on:click="{ ()=> dispatch('close')}" status="cancel" />
      </div>
    </div>
  </Modal>
</div>