<script>
    import { createEventDispatcher } from 'svelte';
  
    import Phalcon from "modules/phalcon.js";
  
    import Modal from "UI/Modal.html";
    import Button from "UI/Button.html";
    import WindowButton from 'UI/ButtonWindowClose.html';
    import Backdrop from 'UI/Backdrop.html'
    import { successMsg, warningMsg, errorMsg } from 'UI/Messages.html'

    export let deliveryService
    
    const POSSIBLE_FIELDS = {
        number: 'Номер',
        date: 'Дата',
        size: 'Размер',
        cargo: 'Груз',
        phone: 'Телефон',
        client: 'Клиент',
        duration: 'Время на выполнение',
        pickups: 'Откуда забрать (Идентификаторы)',
        address: 'Адрес',
        delivery_user_id: 'Курьер (Идентификатор)',
        lat: 'Широта',
        lng: 'Долгота',
        comment: 'Комментарий',
    }

    const dispatch = createEventDispatcher()
    const onClose = () => dispatch("close")

    let history = []
    let statusHistory = []

    function parseHistoryData(item) { 
        let history = {
            action: 'undefined',
            fields: [],
            beforeValue: {},
            afterValue: {},
            date: 'Без даты',
            showDetail: false,
            createdAt: null,
            user: {
                fullName: 'Без имени',
                email: 'Без email',
            }
        }

        if (item.created_at) {
            history.createdAt = item.created_at
        }

        if (item.user_last_name && item.user_first_name) {
            history.user.fullName = `${item.user_last_name} ${item.user_first_name}`
        } else if (item.user_first_name) {
            history.user.fullName = `${item.user_first_name}`
        }

        if (item.user_email) {
            history.user.email = `${item.user_email}`
        }

        let before = null
        let after = null

        if (item.before) before = JSON.parse(item.before)
        if (item.after) after = JSON.parse(item.after)

        if (before && after) {
            history.action = 'change'

            Object.keys(before).forEach(key => {
                if (before[key] != after[key] && POSSIBLE_FIELDS[key]) {
                    history.fields.push(key)
                    history.beforeValue[key] = before[key]
                    history.afterValue[key] = after[key]
                }
            })

            if (history.fields.length == 0) return null
        } else if (!before && after) {
            history.action = 'create'

            Object.keys(after).forEach(key => {
                if (POSSIBLE_FIELDS[key]) history.fields.push(key)
            })

            history.afterValue = after
        } else if (before && !after) {
            history.action = 'delete'
        }

        // console.log({history})
        return history
    }

    let fetchHistoryLoading = false
    const fetchHistory = async () => {
        if (deliveryService && deliveryService.id && !fetchHistoryLoading){
            fetchHistoryLoading = true

            const answer = await Phalcon.api.delivery.services.history(deliveryService.id)

            if(answer.status == 200) {
                history = answer.payload.history.map(item => parseHistoryData(item)).filter(item => item)
                statusHistory = answer.payload.history_status
            } else {
                errorMsg(answer.message)
            }

            fetchHistoryLoading = false
        }
    }

    fetchHistory()
</script>
  
{#if deliveryService}
    <Modal>
        <div class="uk-flex uk-flex-between" slot="header">
            <div class="uk-text-lead">История заказа №{deliveryService.number}</div>
            <WindowButton type="plus" on:click={onClose}></WindowButton>
        </div>

        <div slot="body" class="uk-padding-remove">
            {#if fetchHistoryLoading}
                <Backdrop>
                    <div uk-spinner></div>
                </Backdrop>
            {/if}

            <div uk-grid>
                <div class="uk-width-1-2">
                    <h4>Данные</h4>
                    {#each history as item}
                        <div class="uk-card uk-card-default uk-card-small uk-card-body uk-margin-small-bottom">
                                <div uk-grid>
                                    <div class="uk-width-auto uk-flex uk-flex-center uk-flex-middle">
                                        <span uk-icon="icon: user; ratio: 1.5;"></span>
                                    </div>
                                    <div class="uk-width-expand">
                                        <p class="uk-margin-remove-bottom">{item.user.fullName}</p>
                                        <span class="uk-text-meta uk-margin-small-bottom">{item.user.email}</span>
                                    </div>
                                </div>
                                <div class="uk-flex uk-flex-middle uk-margin-small-top">
                                    <span class="uk-text-meta uk-margin-small-right">{moment(item.createdAt, 'YYYY-MM-DD HH:mm:ss').add(3, 'hours').format('DD.MM.YYYY HH:mm')}</span>
                                        
                                    {#if item.action == 'create'}
                                        <span class="uk-label" style="background-color: darkblue; font-size: 10px;">Создал</span>
                                    {/if}
                                    {#if item.action == 'change'}
                                        <span class="uk-label" style="background-color: darkorange;; font-size: 10px;">Изменил</span>
                                    {/if}
                                    {#if item.action == 'delete'}
                                        <span class="uk-label" style="background-color: darkred;; font-size: 10px;">Удалил</span>
                                    {/if}
                                </div>

                            
                            <div class="uk-grid-small uk-margin-small-top" uk-grid>
                                <div class="uk-width-expand">
                                    <label class="uk-text-meta">Подробности </label>
                                </div>
                                <div class="uk-width-auto uk-flex">
                                    {#if item.showDetail}
                                        <a uk-icon="chevron-up" on:click="{() => item.showDetail = false}"></a>
                                    {:else}
                                        <a uk-icon="chevron-down" on:click="{() => item.showDetail = true}"></a>
                                    {/if}
                                </div>
                            </div>


                            {#if item.showDetail}

                                {#if item.action == 'create'}
                                    {#each item.fields as fieldKey}
                                        <div class="uk-grid-small" uk-grid>
                                            <div class="uk-width-auto">
                                                <span class="uk-text-meta">{POSSIBLE_FIELDS[fieldKey]}</span>
                                            </div>
                                            <div class="uk-width-expand uk-text-right">
                                                <span>{item.afterValue[fieldKey]}</span>
                                            </div>
                                        </div>
                                    {/each}
                                {/if}

                                
                                {#if item.action == 'change'}
                                    {#each item.fields as fieldKey}
                                        <div class="uk-grid-small" uk-grid>
                                            <div class="uk-width">
                                                <span class="uk-text-meta">{POSSIBLE_FIELDS[fieldKey]}</span>
                                            </div>
                                            <div class="uk-width-expand">
                                                <s>{item.beforeValue[fieldKey]}</s>
                                            </div>
                                            <div class="uk-width-auto">
                                                ->
                                            </div>
                                            <div class="uk-width-expand">
                                                <span>{item.afterValue[fieldKey]}</span>
                                            </div>
                                        </div>
                                    {/each}
                                {/if}

                                {#if item.action == 'delete'}
                                    Действие: Удалил<br>
                                {/if}

                            {/if}
                        </div>
                    {/each}
                </div>
                <div class="uk-width-1-2">
                    <h4>Статусы</h4>
                    {#each statusHistory as item}
                        <div class="uk-card uk-card-default uk-card-small uk-card-body uk-margin-small-bottom">
                            <div uk-grid>
                                <div class="uk-width-auto uk-flex uk-flex-center uk-flex-middle">
                                    <span uk-icon="icon: user; ratio: 1.5;"></span>
                                </div>
                                <div class="uk-width-expand">
                                    <p class="uk-margin-remove-bottom">{item.user_last_name} {item.user_first_name}</p>
                                    <span class="uk-text-meta uk-margin-small-bottom">{item.user_email}</span>
                                </div>
                            </div>
                            <div class="uk-flex uk-flex-middle uk-margin-small-top">
                                <span class="uk-text-meta uk-margin-small-right">{moment(item.created_at, 'YYYY-MM-DD HH:mm:ss').add(3, 'hours').format('DD.MM.YYYY HH:mm')}</span>
                                    
                                <span class="uk-label status-label" style="--background-color: {item.status.color}; font-size: 10px;">{item.status.name}</span>
                            </div>
                        </div>
                    {/each}
                </div>
            </div>
        </div>

        <div slot="footer" class="uk-flex uk-flex-right">
            <Button on:click={onClose}>Закрыть</Button>
        </div>
    </Modal>
{/if}

<style>
    .status-label {
        background-color: var(--background-color);
    }
</style>